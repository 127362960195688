const $ = require('jquery');
const lazyImages = require('./../components/ui-components/LazyLoad.js').lazyImages;
const support = require('./../support.js');
require('./../components/ui-components/modal-bootstrap-v3.4.1');
const videoPopup = require('./../components/ui-components/VideoPopup').videoPopup;

const navigationToggle = function() {
    let navigationToggler = document.querySelector('.js-nav-toggle');
    let navigation = document.querySelector('.js-nav');

    let body = document.querySelector('body');
    let bodyOverlay = document.createElement('div');
    bodyOverlay.classList.add('body-overlay', 'js-body-overlay');

    navigationToggler.addEventListener('click', function(event) {
        if (navigation.classList.contains('shown')) {
            this.classList.remove('on');
            navigation.classList.remove('shown');
            body.classList.remove('menu-opened');
            document.querySelector('.js-body-overlay').remove();
        } else {
            this.classList.add('on');
            navigation.classList.add('shown');
            body.classList.add('menu-opened');
            body.appendChild(bodyOverlay);
        }
    });

    document.addEventListener('click', function(event) {
        let target = event.target;

        if (target !== navigationToggler) {
            navigationToggler.classList.remove('on');
            navigation.classList.remove('shown');
            body.classList.remove('menu-opened');
            if (document.querySelector('.js-body-overlay')) {
                document.querySelector('.js-body-overlay').remove();
            }
        }
    });
};


// const priceCalculator = function() {
//     let basicPrice = 9.9,
//         standardPrice = 29.9,
//         premiumPrice = 59.9;
//     let companiesCounter = document.querySelector('.js-pricing-counter'),
//         companiesMobileCounter = document.querySelector('.js-pricing-counter-mobile');
//
//     let basicPriceWrapper = document.querySelector('.js-price-basic'),
//         standardPriceWrapper = document.querySelector('.js-price-standard'),
//         premiumPriceWrapper = document.querySelector('.js-price-premium');
//
//     let basicPriceMobileWrapper = document.querySelector('.js-price-basic-mobile'),
//         standardPriceMobileWrapper = document.querySelector('.js-price-standard-mobile'),
//         premiumPriceMobileWrapper = document.querySelector('.js-price-premium-mobile');
//
//
//     function updatePrice() {
//         let amount = ~~this.value;
//
//         // Reset value to max/min
//         if (amount > 500) {
//             this.value = 500;
//             amount = 500;
//         } else if (amount < 0) {
//             this.value = 0;
//             amount = 0;
//         }
//
//         if (Number.isInteger(amount) && amount >= 0) {
//             basicPriceWrapper.innerText = '$' + (basicPrice * amount).toFixed(1);
//             standardPriceWrapper.innerText = '$' + (standardPrice * amount).toFixed(1);
//             premiumPriceWrapper.innerText = '$' + (premiumPrice * amount).toFixed(1);
//
//             basicPriceMobileWrapper.innerText = '$' + (basicPrice * amount).toFixed(1);
//             standardPriceMobileWrapper.innerText = '$' + (standardPrice * amount).toFixed(1);
//             premiumPriceMobileWrapper.innerText = '$' + (premiumPrice * amount).toFixed(1);
//         }
//     }
//
//     companiesCounter.addEventListener('input', updatePrice);
//     companiesMobileCounter.addEventListener('input', updatePrice);
// };


const smoothAnchorScroll = function() {
    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);

        window.location.hash = this.hash;
    });
};


const parallaxEffect = function(parent) {
    window.addEventListener('scroll', function() {
        let shapes = document.querySelectorAll(parent + ' .js-shape');
        let distance;
        let scrollDistances = [];

        if (parent === '.cover') {
            distance = window.scrollY;
            scrollDistances = [0.3, 0.2, -0.1, 0.1, -0.2];
        } else {
            distance =  document.querySelector(parent).offsetTop - window.scrollY;
            scrollDistances = [0.3, 0.2, -0.1, 0.1, -0.2, 0.3];
        }

        for (let i = 0; i < shapes.length; i++) {
            for (let j = 0; j < scrollDistances.length; j++) {
                shapes[j].style.transform = 'translateY(' + distance * scrollDistances[j] + 'px)';
            }
        }
    });
};


const openWidget = function() {
    let widgetButton = document.querySelectorAll('.js-widget-btn');

    widgetButton.forEach(function(button) {
        button.addEventListener('click', function() {
            document.querySelector('#sb_enterprise_widget').click();
        });
    });
};


const featuresHighlightOnScroll = function() {
    const tabletWidth = 1024;

    if ($(window).width() > tabletWidth) {
        window.addEventListener('scroll', function () {
            let documentViewTop = $(window).scrollTop();
            let documentViewBottom = documentViewTop + $(window).height();

            $('.js-features-item').each(function () {
                let elementTop = $(this).offset().top + 100;
                let elementBottom = elementTop + $(this).height();

                if ((elementBottom <= documentViewBottom) && (elementTop >= documentViewTop)) {
                    $(this).addClass('on-screen');
                } else {
                    $(this).removeClass('on-screen')
                }
            });
        });
    } else {
        $('.js-features-item').each(function () {
           $(this).addClass('on-screen');
        });
    }
};

const scrollTopArrow = function() {
    const mobileWidth = 768;
    let coverHeight = $('.cover').height() + 125; // Header offset
    let scrollTopElement = $('.scroll-top');
    let lastScrollTop = 0;

    if ($(window).width() < mobileWidth) {
        window.addEventListener('scroll', function () {
            let currentScroll = $(this).scrollTop();
            if (currentScroll > coverHeight) {
                if (currentScroll < lastScrollTop) {
                    scrollTopElement.addClass('shown');
                } else {
                    scrollTopElement.removeClass('shown');
                }
                lastScrollTop = currentScroll;
            } else {
                scrollTopElement.removeClass('shown');
            }
        });

        scrollTopElement.click(function() {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        });
    }
};


// Handle lazy loading images
lazyImages();
support.supportChat();

navigationToggle();
// priceCalculator();
smoothAnchorScroll();
parallaxEffect('.cover');
parallaxEffect('.pricing');
openWidget();
featuresHighlightOnScroll();
scrollTopArrow();
videoPopup();